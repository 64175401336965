<template>
  <MasterDetail
    formTitle="Cadastro de Folhas de Pagamento"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    resourceListProperty="fopags"
    descriptionProperty="id"
    :actionBarButtons="actionBarButtons"
    :contextOptions="options"
    has-year-filter
  ></MasterDetail>
</template>

<script>
import { mapGetters } from 'vuex';
import MasterDetail from "@/components/master-detail.vue";
import { TipoImportacaoEnum } from "@/core/enums/importacoes";

export default {
  components: {
    MasterDetail,
  },
  created: function () {
    this.funcionarioResource.get().then((response) => {
      this.opts.Funcionario = response.funcionario;
    });
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons: function () {
      const buttons = [
         {
          text: "Alocação de Horas",
          icon: "mdi-clock-time-eight-outline",
          show: this.userHasAccess("Timesheet.alocacaoDeHoras.index"),
          action: () => {
            this.$router.push({ name: "timesheets", query: { funcionarioId: this.colaboradorId }});
          },
        },
        {
          text: "Dispêndio",
          icon: "mdi-calculator-variant-outline",
          show: this.userHasAccess("Dispendios.recursosHumanos.index"),
          action: () => {
            this.$router.push({ name: "dispendio-rh", query: { colaboradorId: this.colaboradorId }});
          },
        },
        {
          text: "Importar FOPAG",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.folhaDePagamento"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-fopag", query: { tipo: TipoImportacaoEnum.RH_FOPAG }});
          },
        },
        {
          text: "Importar Provisões",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.provisoes"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-provisoes", query: { tipo: TipoImportacaoEnum.RH_PROVISOES }});
          },
        },
        {
          text: "Importar Controle Ponto",
          icon: "mdi-import",
          show: this.userHasAccess("Importacoes.recursosHumanos.controleDePonto"),
          action: () => {
            this.$router.push({ name: "listagem-importacoes-ponto", query: { tipo: TipoImportacaoEnum.RH_PONTO }});
          },
        },
      ];

      return buttons.filter(({ show }) => show);
    },
    colaboradorId: function () {
      return this.$route.params.colaboradorId;
    },
    funcionarioResource: function () {
      return this.apiResource(`/v1/rh/${this.clientId}/colaboradores/${this.colaboradorId}`);
    },
    options: function() {
      return this.userHasAccess("Colaboradores.folhaDePagamento.index") ? [
        {
          name: "Ver Rubricas",
          limit: 1,
          icon: "",
          cb: (row) => {
            this.$router.push({ name: "itens-folha-pagamento-colaborador", params: { colaboradorId: this.colaboradorId, fopagId: row.id }});
          },
        },
      ] : [];
    },
    resourceUrl: function () {
      return `/v1/rh/${this.clientId}/colaboradores/${this.colaboradorId}/fopag`;
    },
  },
  data: function () {
    return {
      cols: [
        { key: "id", name: "FOPAG ID", align: 1, hideInform: true, type: 0 },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
        },
        {
          key: "status",
          name: "Status Cálculo LB",
          editable: false,
          hideInform: true,
          rel: { to: "status", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
        },
        {
          key: "statusLI",
          name: "Status Cálculo LI",
          editable: false,
          hideInform: true,
          rel: { to: "status", key: "id", name: "nome" },
          type: this.$fieldTypes.SELECT,
        },
        {
          key: "jobId",
          name: "ID Importação",
          hideInform: true,
          type: 0,          
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
        {
          key: "criado_em",
          name: "Data de Criação",
          align: 1,
          editable: false,
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
           hideInform: true,
        },
        
      ],
      opts: {
        Funcionario: [],
        status: [
          { id: 0, nome: "Novo" },
          { id: -1, nome: "Erro no Cálculo" },
          { id: 11, nome: "Cálculo Finalizado"},
          { id: 1, nome: "Calculada" },
          { id: 2, nome: "A recalcular" },
          { id: 102, nome: "Alterada - Rubrica Editada" },
          { id: 103, nome: "Alterada - Timesheet" },
          { id: 105, nome: "Alterada - De-Para Funcionário" },
          { id: 201, nome: "Alterada - Pareamento de Rubricas" },
          { id: 202, nome: "Alterada - Encargos" },
          { id: 301, nome: "Alterada - Lei do Projeto" },
          { id: 302, nome: "Alterada - Projeto Reprovado" },
          { id: 303, nome: "Alterada - Projeto Aprovado" },
          { id: 20, nome: "Calculando Ajuste" },
          { id: 30, nome: "Calculando Ajuste" }
        ],
      },
    };
  },
  watch: {
    clientId: function () {
      this.$router.push({ name: "listagem-colaboradores" });
    },
  },
};
</script>
